import { mapState, mapStores } from 'pinia'
import { defineComponent } from 'vue'

import SegmentMixin from '~/mixins/Segment/Segment'

import { useDraftStore } from '~/stores/draft'
import { useDraftPricingStore } from '~/stores/draftPricing'
import { useDraftTrackStore } from '~/stores/draftTrack'
import { useDraftTrackTagsStore } from '~/stores/draftTrackTags'
import { useMiscSendtrackFiltersStore } from '~/stores/miscSendtrackFilters'
import { useTagStore } from '~/stores/tag'
import { useUserBandStore } from '~/stores/userBand'

import { TagTypeKeys } from '~/enums/TagTypeKeys'

import type Tag from '~/entities/tag'
import type Band from '~/types/band'
import type {
  CampaignBaseProperties,
  CampaignInitiatedFrom,
} from '~/types/Segment/SegmentEventLibrary/SegmentEventLibraryCampaign'
import type TagTypeKey from '~/types/tagTypeKey'

/**
 * @deprecated use composables/useSegmentTrackCampaign.ts
 */
export default defineComponent({
  mixins: [SegmentMixin],
  computed: {
    ...mapStores(useDraftStore, useDraftTrackStore, useDraftTrackTagsStore),
    ...mapState(useMiscSendtrackFiltersStore, {
      SELECTED_FILTERS: 'selected_tag_ids',
    }),
    ...mapState(useUserBandStore, ['BAND_DATA']),
    ...mapState(useTagStore, ['GET_TAGS_FROM_IDS', 'GET_TAGS_FROM_TYPE_NAME']),
    ...mapState(useDraftPricingStore, {
      MISSING_GROOVIZ: 'MISSING',
      NEED_GROOVIZ: 'NEED_PURCHASE',
    }),
  },
  methods: {
    /**
     * @deprecated use composables/useSegmentTrackCampaign.ts
     */
    getBaseDraftData(): CampaignBaseProperties {
      return {
        band_name: (this.BAND_DATA as Band).name,
        id_band: (this.BAND_DATA as Band).id,
        id_draft: this.draftStore.id,
      }
    },
    /**
     * @deprecated use composables/useSegmentTrackCampaign.ts
     */
    getSelectedTagNamesForTagType(tagTypeName: TagTypeKey): string[] {
      return (this.GET_TAGS_FROM_TYPE_NAME(tagTypeName) as Tag[])
        .filter((tag) => this.SELECTED_FILTERS.includes(tag.id))
        .map((tag) => tag.name)
    },
    /**
     * @deprecated use composables/useSegmentTrackCampaign.ts
     */
    trackCampaignInitiated(campaignInitiatedFrom: CampaignInitiatedFrom): void {
      const { id_band, band_name } = this.getBaseDraftData()
      this.trackSegmentEvent('Campaign - New Campaign Initiated', {
        id_band,
        band_name,
        page_title: this.$route.name?.toString() ?? null,
        new_campaign_initiated_from: campaignInitiatedFrom,
      })
    },
    /**
     * @deprecated use composables/useSegmentTrackCampaign.ts
     */
    trackCustomMessagesDisplayToggled(isDisplayed: boolean): void {
      this.identifyLogin({
        user_id: (this.BAND_DATA as Band).id,
      })
      this.trackSegmentEvent('Messages - Personalized Messages Toggled', {
        id_draft: this.draftStore.id,
        toggle_status: isDisplayed ? 'toggled_on' : 'toggled_off',
        num_curators_selected: this.draftStore.influencers.length,
      })
    },
    /**
     * @deprecated use composables/useSegmentTrackCampaign.ts
     */
    trackTrackStepFinalized(step: number): void {
      if (step === 1) {
        const trackLangTags = this.GET_TAGS_FROM_IDS(
          this.draftTrackTagsStore.identity.lyrics_lang ?? [],
        ) as Tag[]
        const trackLangs = trackLangTags.map((lang) => lang.name)

        this.trackSegmentEvent('Campaign - Step 1 - Track Step Finalized', {
          band_name: (this.BAND_DATA as Band).name,
          release_date: this.draftTrackStore.release_date
            ? new Date(this.draftTrackStore.release_date)
            : undefined,
          has_shared_track_ep_link: this.draftTrackStore.ep_link.length > 0,
          has_shared_track_spotify_link:
            this.draftTrackStore.secondary_link.length > 0,
          has_shared_track_youtube_or_soundcloud_link:
            this.draftTrackStore.link.length > 0,
          id_band: (this.BAND_DATA as Band).id,
          id_draft: this.draftStore.id,
          is_demo: this.draftTrackStore.is_demo,
          track_languages_list: trackLangs || null,
          track_title: this.draftTrackStore.name,
        })
      } else if (step === 4) {
        this.identifyLogin({
          id_band: (this.BAND_DATA as Band).id,
        })
        this.trackSegmentEvent('Campaign - Step 4 - Track Links Finalized', {
          soundcloud_or_yt_link: this.draftTrackStore.link,
          has_shared_track_spotify_link:
            this.draftTrackStore.secondary_link.length > 0,
          has_shared_track_ep_link: this.draftTrackStore.ep_link.length > 0,
          has_shared_track_youtube_or_soundcloud_link:
            this.draftTrackStore.link.length > 0,
          id_draft: this.draftStore.id,
          id_track: this.draftTrackStore.id,
        })
      }
    },
    /**
     * @deprecated use composables/useSegmentTrackCampaign.ts
     */
    trackGoalsStepFinalized(): void {
      const filterTagNames = (
        this.GET_TAGS_FROM_IDS(this.draftStore.tags) as Tag[]
      ).map((tag) => tag.name)

      this.trackSegmentEvent('Campaign - Step 2 - Goals Step Finalized', {
        ...this.getBaseDraftData(),
        budget_range: undefined,
        campaign_goals_list: this.draftStore.goals,
        has_specified_budget: false,
        preselection_filter_subcategory_list:
          filterTagNames && filterTagNames.length ? filterTagNames : undefined,
      })
    },
    /**
     * @deprecated use composables/useSegmentTrackCampaign.ts
     */
    trackCuratorsSelectionValidated(): void {
      const tags = this.GET_TAGS_FROM_IDS(this.SELECTED_FILTERS) as Tag[]
      const tagTypesToGet = Object.keys(TagTypeKeys) as TagTypeKey[]
      const selectedTagNamesByType = tagTypesToGet.reduce(
        (acc, tagType) => {
          acc[tagType] = this.getSelectedTagNamesForTagType(tagType)
          return acc
        },
        {} as Record<TagTypeKey, string[]>,
      )

      this.trackSegmentEvent(
        'Campaign - Step 2 - Curators Selection Validated',
        {
          ...this.getBaseDraftData(),
          badges_filtered_to: selectedTagNamesByType.influencer_badge,
          countries_filtered_to: selectedTagNamesByType.country,
          curator_types_filtered_to: selectedTagNamesByType.influencer_kind,
          has_filtered_to_new_curators:
            selectedTagNamesByType.influencer_mark.includes('new'),
          id_track: this.draftTrackStore.id,
          interactions_filtered_to: selectedTagNamesByType.interaction,
          music_genres_filtered_to: selectedTagNamesByType.subgenre,
          num_curators_selected: this.draftStore.influencers.length,
          postselection_filter_subcategory_list: [
            ...tags.map((tag) => tag.name),
          ],
        },
      )
    },
    /**
     * @deprecated use composables/useSegmentTrackCampaign.ts
     */
    trackCuratorsSelectionFinalized(): void {
      this.trackSegmentEvent('Campaign - Step 2 - Selection Step Finalized', {
        ...this.getBaseDraftData(),
        num_curators_selected: this.draftStore.influencers.length,
      })
    },
    /**
     * @deprecated use composables/useSegmentTrackCampaign.ts
     */
    trackMessageStepFinalized(): void {
      this.trackSegmentEvent('Campaign - Step 3 - Messages Step Finalized', {
        ...this.getBaseDraftData(),
        has_enough_grooviz_for_the_campaign: !this.NEED_GROOVIZ,
        has_written_personal_message:
          Object.keys(this.draftStore.messages).length > 0,
        has_written_pitch: (this.draftStore.info?.length ?? 0) > 0,
      })
    },
    /**
     * @deprecated use composables/useSegmentTrackCampaign.ts
     */
    trackCampaignSummaryFinalized(
      buttonAction: 'send campaign' | 'go to payment',
    ): void {
      this.trackSegmentEvent('Campaign - Step 4 - Campaign Summary Finalized', {
        ...this.getBaseDraftData(),
        button_action: buttonAction,
        num_missing_grooviz: this.MISSING_GROOVIZ,
      })
    },
  },
})
